import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueParticles from 'vue-particles'
import  "./assets/font.css"
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'


createApp(App)
    .use(store)
    .use(router)
    .use(VueParticles)
    .use(ElementPlus)
    .mount('#app')
